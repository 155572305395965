import './Library.css'
import React from 'react';

class LibraryListHeader extends React.Component {

    render () {
        return <div className="libraryListHeader">
            <div className="libraryHeaderItem">{this.props.messages.LibraryListHeader.render.tag}</div>
            <div className="libraryHeaderItem">{this.props.messages.LibraryListHeader.render.title}</div>
            <div className="libraryHeaderItem">{this.props.messages.LibraryListHeader.render.author}</div>
            <div className="libraryHeaderItem">{this.props.messages.LibraryListHeader.render.status}</div>
            <div className="libraryHeaderItem">{" "}</div>
        </div>
    }
}

export { LibraryListHeader };