const settings = {

    // apiHost: 'ec2-54-91-22-211.compute-1.amazonaws.com',
    // apiHost: 'http://10.0.4.65:5000',
    // apiHost: 'https://hx892rkc1e.execute-api.eu-central-1.amazonaws.com',
    // apiHost: 'https://elb-lambda.alite.link',
    // apiHost: 'https://apig-lambda.alite.link',
    build: '15',
    // apiHost: 'https://1jd2x5acej.execute-api.ca-central-1.amazonaws.com',
    modes: [
        // {mode: 'EC2+AUR', apiHost: 'https://elb-aurora.alite.link'},
        {mode: 'EC2+AUR', apiHost: 'https://elb-rds.alite.link'},
        {mode: 'EC2+DDB', apiHost: 'https://elb-lambda.alite.link'},
        {mode: 'LAM+DDB', apiHost: 'https://hx892rkc1e.execute-api.eu-central-1.amazonaws.com'},
    ]
} 

export default settings