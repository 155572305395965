import React from 'react';

class LibraryInfoDisplay extends React.Component {

    render () {

        return <div className="card two-column controllerCard">
                    <div className="wideColumnCentered bold orange">
                        {this.props.messages.LibraryInfoDisplay.render.title}
                    </div>
                    <div>
                        {this.props.messages.LibraryInfoDisplay.render.totalBooks}
                    </div>
                    <div>
                        {this.props.totalBooks}
                    </div>
                    <div>
                        {this.props.messages.LibraryInfoDisplay.render.onShelf}
                    </div>
                    <div>
                        {this.props.onShelf}
                    </div>
                    <div>
                        {this.props.messages.LibraryInfoDisplay.render.build}
                    </div>
                    <div>
                        {this.props.build}
                    </div>
                    <div className="wideColumnCentered infoMessage">
                        {this.props.message}
                    </div>
                </div>

    }

}

export { LibraryInfoDisplay } ;