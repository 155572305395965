import React from 'react';
import { LibraryComponent } from './components/LibraryComponent.js'
import './App.css';

function App() {
  return (
    <LibraryComponent />
  );
}

export default App;
