const messages_en_CA = {
    languageLabel: "English",

    LibraryListHeader: {
        render: {
            tag: "Tag:",
            title: "Title:",
            author: "Author:",
            status: "Status:",
        }
    },

    LibraryCard: {
        render: {
            checkedIn: "In",
            checkedOut: "Out",
            checkIn: "Check In",
            checkOut: "Check Out",
            retire: "Retire"
        }
    },
    LibraryInfoDisplay: {
        render: {
            title: "ALite Launchpad",
            totalBooks: "Total Books:",
            onShelf: "On Shelf:",
            build: "Software Build:"
        }
    },
    LibraryController: {
        render: {
            controller: "Add Book",
            title: "Title:",
            author: "Author:",
            randomize: "Randomize",
            add: "Add",
        }
    },
    LibraryComponent: {
        constructor: {
            tryingToLoadTheLibrary: "Trying to load the library...",
        },

        fetchAll: {
            libraryLoadedOK: "Library loaded ok.",
            couldNotLoadLibrary: "Could not load the library.",    
        },

        modeHandler: {
            libraryReloadedOK: "Library reloaded ok.",
            couldNotReloadLibrary: "Could not reload the library.",    
        },

        checkOutHandler: {
            tryingToCheckOut: "Trying to check out book %s1...",
            alreadyCheckedOut: "Book %s1 is already checked out.",
            checkOutOK: "Book %s1 checked out successfully.",
            checkOutFailed: "Could not check out book %s1.",
        },

        checkInHandler: {
            tryingToCheckIn: "Trying to check in book %s1...",
            alreadyCheckedIn: "Book %s1 is already checked in.",
            checkInOK: "Book %s1 checked in successfully.",
            checkInFailed: "Could not check in book %s1.",
        },

        retireHandler: {
            tryingToRetire: "Trying to retire book %s1...",
            retiredOK: "Book %s1 retired successfully.",
            couldNotRetire: "Could not retire book %s1.",
        },

        addBookHandler: {
            bookAddedOK: "Book has been added.",
            weNeedBookAuthor: "We want our books to have authors.",    
            weNeedBookTitle: "We want our books to have titles.", 
            couldNotAddBook: "Could not add book.",
            tryingToAddBook: "Trying to add book..."   
        },
    }    
}
export default messages_en_CA;