import { Book } from './book.js';

// here's how i want my books
// array of objects containing book and key
// [ {city: {city}, {tag: autoincrement } }]


class Library {

    constructor () {
        this.library = []
    }

    flush () {
        this.library = []
    }

    sort () {
        this.library.sort( (a,b) => a.book.getTitle().localeCompare(b.book.getTitle()) )
    }

    getBookList () {
        return this.library.map ( value => value.tag );
    }

    addBook(tag, title, author, onShelf = true) {
        const processedTag = tag.toString()
        const newBook = new Book(title, author)
        this.library.push({"book": newBook, "tag": processedTag, "onShelf": onShelf})
        return processedTag
    }

    retireBook(tag) {
        // arr.findIndex(callback(element[, index[, array]])[, thisArg])
        const myTestingFunction = (element) => element.tag === tag;
        const myIndex = this.library.findIndex (myTestingFunction);
        if (myIndex !== -1) {
            this.library.splice(myIndex,1)
        } else {
            tag = false
        }
        return tag
    }

    getBook (tag) {
        const myTestingFunction = (element) => element.tag === tag;
        return this.library.find(myTestingFunction);
    }

    getTitle (tag) {
        return this.getBook(tag).book.getTitle()
    }

    getAuthor (tag) {
        return this.getBook(tag).book.getAuthor()
    }

    isOnShelf (tag) {
        return this.getBook(tag).onShelf
    }

    checkOut (tag) {
        const book = this.getBook(tag)
        const result = book.onShelf
        book.onShelf = false
        return result
    }

    checkIn (tag) {
        const book = this.getBook(tag)
        const result = ! book.onShelf
        book.onShelf = true
        return result
    }

    size () {
        return this.library.length
    }

    countOnShelf () {
        const reducer = function (previousCount, newBook) {
            return previousCount + (newBook.onShelf ? 1 : 0)
        }
        return this.library.reduce(reducer, 0)
    }

}

export { Library }


