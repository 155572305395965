class Book {
    constructor (title, author) {
        this.title = title ? title.toString() : "Unknown"
        this.author = author ? author.toString() : "Unknown"
    }

    getTitle() { return this.title }
    getAuthor() { return this.author }
}

export { Book };