import React from 'react';
import { LibraryListHeader } from './LibraryListHeader.js'
import { LibraryCard } from './LibraryCard.js';
import { LibraryInfoDisplay } from './LibraryInfoDisplay.js';
import { LibraryController } from './LibraryController.js'
import { LibrarySettings } from './LibrarySettings.js'
import { Library } from "./library.js";
import './Library.css';

// import locales
import messages_en_CA from '../strings/messages_en_CA.js';

// import general settings
import settings from './settings.js'
// const apiHost = settings.apiHost

class LibraryComponent extends React.Component {


    constructor(props) {

        super(props) ;

        this.defaultMode = "LAM+DDB"

        this.state = {
            build: settings.build,
            modes: settings.modes,
            endpoints: {
                frontend: '',
                backend: '',
                database: '',                    
            },
            message: "Trying to load the library.",
            library: new Library(),
            language: "en_CA",
            mode: "",
            messages: {
                "en_CA": messages_en_CA,
            },
        }

        let storedMode = localStorage.getItem('mode')

        if (storedMode !== null) {
            this.state.mode = storedMode
        } else {
            this.state.mode = this.defaultMode
            localStorage.setItem('mode', this.defaultMode)
        }

        this.state.apiHost = settings.modes.find ( (value) => value.mode === this.state.mode ).apiHost
        console.log(this.state.apiHost)

        this.messageBuilder = () => "Trying to load the library.";
    }

    componentDidMount () {
        this.initialize()
    }

    initialize = async () => {
        const status = await this.fetchAll()
        if (status === 0) {
            this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.fetchAll.libraryLoadedOK
        } else {
            this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.fetchAll.couldNotLoadLibrary
        }
        this.update()
    }

    update = () => {
        const newMessage = this.messageBuilder()
        this.setState({message: newMessage})
    }

    fetchAll = async () => {
        const url = this.state.apiHost + '/api/ddb/all'
        const responseData = await this.fetchHandler(url);
        console.log(responseData)
        this.state.library.flush()
        if (responseData.Status === 0) {
            for (let book of responseData['Library']) {
                this.state.library.addBook(
                    book['tag'],
                    book['title'],
                    book['author'],
                    book['onShelf']
                );        
            }
            this.state.library.sort()
            this.state.endpoints.backend = responseData.Backend
            this.state.endpoints.database = responseData.Database
        }
        return responseData.Status
    }

    fetchRetire = async (tag) => {
        const url = this.state.apiHost + '/api/ddb/retire/' + tag
        const responseData = await this.fetchHandler(url);
        return responseData.Status
    }

    fetchAddBook = async (title, author) => {
        const url = this.state.apiHost + "/api/ddb/add/" + title + '/' + author
        const responseData = await this.fetchHandler(url);
        return responseData.Status
    }

    fetchCheckOut = async (tag) => {
        const url = this.state.apiHost + '/api/ddb/checkout/' + tag
        const responseData = await this.fetchHandler(url);
        return responseData.Status
    }

    fetchCheckIn = async (tag) => {
        const url = this.state.apiHost + '/api/ddb/checkin/' + tag
        const responseData = await this.fetchHandler(url);
        return responseData.Status
    }

    fetchHandler = async (url) => {
        let responseData
        try {
            const response = await fetch(url)
            responseData = await response.json()
        } catch {
            responseData = { 'Status' : -1 }
        }
        return responseData
    }

    //  this is just a testing area

    randomBook = async () => {
        const url = this.state.apiHost + '/api/ddb/randombook'
        const responseData = await this.fetchHandler(url);
        return responseData
    }    

    // 

    addBookHandler = async (bookTitle, bookAuthor) => {
        if (bookTitle.length > 0) {
            if (bookAuthor.length > 0) {
                this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.addBookHandler.tryingToAddBook
                this.update()
                const result = await this.fetchAddBook(bookTitle, bookAuthor);
                if (result === 0) {
                    this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.addBookHandler.bookAddedOK
                } else {
                    this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.addBookHandler.couldNotAddBook
                }
            } else {
                this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.addBookHandler.weNeedBookAuthor
            }
        } else {            
            this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.addBookHandler.weNeedBookTitle
        }
        await this.fetchAll()
        this.update()
    }

    checkOutHandler = async (tag) => {
        let result;
        const shortTag = tag.substring(0,8)
        if (! this.state.library.isOnShelf(tag)) {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkOutHandler.alreadyCheckedOut, shortTag)
            this.update()
        } else {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkOutHandler.tryingToCheckOut, shortTag)
            this.update()
            result = await this.fetchCheckOut(tag);
            if (result === 0) {
                this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkOutHandler.checkOutOK, shortTag)
            } else {
                this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkOutHandler.checkOutFailed, shortTag)
            }
            await this.fetchAll()
            this.update()                    
        }
    }

    checkInHandler = async (tag) => {
        let result;
        const shortTag = tag.substring(0,8)
        if (this.state.library.isOnShelf(tag)) {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkInHandler.alreadyCheckedIn, shortTag)
            this.update()
        } else {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkInHandler.tryingToCheckIn, shortTag)
            this.update()
            result = await this.fetchCheckIn(tag);
            console.log(result);
            if (result === 0) {
                this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkInHandler.checkInOK, shortTag)
            } else {
                this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.checkInHandler.checkInFailed, shortTag)
            }
            await this.fetchAll()
            this.update()                    
        }
    }

    retireHandler = async (tag) => {
        const shortTag = tag.substring(0,8)
        this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.retireHandler.tryingToRetire, shortTag)
        this.update()

        const result = await this.fetchRetire(tag)
        if (result === 0) {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.retireHandler.retiredOK, shortTag)
        } else {
            this.messageBuilder = () => this.parser( this.state.messages[this.state.language].LibraryComponent.retireHandler.couldNotRetire, shortTag)
        }
        await this.fetchAll()
        this.update()
    }

    modeHandler = async (event) => {
        localStorage.setItem('mode', event.currentTarget.value)

        console.log(this.state.modes.find ( 
            (value) => (value.mode === event.currentTarget.value)).apiHost)

        this.state.mode = event.currentTarget.value
        this.state.apiHost = this.state.modes.find ( 
                (value) => (value.mode === event.currentTarget.value)).apiHost

        const status = await this.fetchAll()
        if (status === 0) {
            this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.modeHandler.libraryReloadedOK
        } else {
            this.messageBuilder = () => this.state.messages[this.state.language].LibraryComponent.modeHandler.couldNotReloadLibrary
        }
        this.update()
    }

    // helper functions

    parser = (...args) => {
        const str = args[0];
        const params = args.filter((arg, index) => index !== 0);
        if (!str) return "";
        return str.replace(/%s[0-9]+/g, matchedStr => {
          const variableIndex = matchedStr.replace("%s", "") - 1;
          return params[variableIndex];
        });
    }


    render () {

        return <div>
                    <div className="controller">
                        <LibraryInfoDisplay
                            totalBooks = {this.state.library.size()}
                            onShelf = {this.state.library.countOnShelf()}
                            message = {this.messageBuilder()}
                            messages = {this.state.messages[this.state.language]}
                            build = {this.state.build}
                        />

                        <LibraryController
                            addBookHandler = {this.addBookHandler}
                            randomBook = {this.randomBook}
                            messages={this.state.messages[this.state.language]}
                        />
                        
                        <LibrarySettings
                            modeHandler = {this.modeHandler}
                            messages = {this.state.messages}
                            modes = {this.state.modes}
                            currentLanguage = {this.state.language}
                            endpoints = {this.state.endpoints}
                            mode = {this.state.mode}
                        />

                    </div>
                    <LibraryListHeader
                        messages={this.state.messages[this.state.language]}
                    />

                    <div>
                        {this.state.library.getBookList().map(
                            (tag) => <LibraryCard
                                            title={this.state.library.getTitle(tag)} 
                                            author={this.state.library.getAuthor(tag)}
                                            onShelf={this.state.library.isOnShelf(tag)}
                                            checkInHandler={this.checkInHandler}
                                            checkOutHandler={this.checkOutHandler}
                                            retireHandler={this.retireHandler}
                                            tag={tag}
                                            key={tag}
                                            messages={this.state.messages[this.state.language]}
                                        />
                        )}
                    </div>
                </div>

    }

}

export { LibraryComponent } ;