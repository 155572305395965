import React from 'react';
import './Library.css';

class LibraryCard extends React.Component {


    constructor (props) {
        super(props);
        this.myRef = React.createRef();
    }

    render () {
        let checkButton;
        if (this.props.onShelf) {
          checkButton =  <div className="centered">
                        <button className="btnC"
                            onClick={
                                () => { 
                                    this.props.checkOutHandler(this.props.tag);
                                }
                            }>
                            {this.props.messages.LibraryCard.render.checkOut}
                        </button>
                    </div>
        } else {
          checkButton =  <div className="centered">
                        <button className="btnC"
                            onClick={
                                () => { 
                                    this.props.checkInHandler(this.props.tag);
                                }
                            }>
                            {this.props.messages.LibraryCard.render.checkIn}
                        </button>
                    </div>
        }

        return <div className="libraryCard">
            <div className="libraryCardItem">{this.props.tag.substring(0,8)}</div>
            <div className="libraryCardItem">{this.props.title}</div>
            <div className="libraryCardItem">{this.props.author}</div>
            <div className="libraryCardItem">
                {this.props.onShelf ? this.props.messages.LibraryCard.render.checkedIn : this.props.messages.LibraryCard.render.checkedOut}
            </div>
            <div className="libraryCardItem">
                {checkButton}
            </div>
            <div className="libraryCardItem">
                <button className="btnC"
                    onClick={
                        () => { 
                            this.props.retireHandler(this.props.tag);
                        }
                    }>
                    {this.props.messages.LibraryCard.render.retire}
                </button>
            </div>
        </div>
    }
}

export { LibraryCard } ;