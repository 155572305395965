import React from 'react';

class EndpointLabel extends React.Component {
    render () {
        return <div>
                    <input id={this.props.mode} 
                        type="radio" name="mode" 
                        value={this.props.mode}
                        onClick={this.props.modeHandler}
                        defaultChecked={this.props.mode === this.props.currentMode}
                    />
                    <label htmlFor={this.props.mode}>{this.props.mode}</label><br></br>
                </div>
    }
}

class LibrarySettings extends React.Component {

    render () {

        return <div className="card three-column controllerCard">
                    <div className="wide3ColumnCentered orange bold">Status</div>

                    <div>Front End:</div>
                    <div className="wideColumn">{window.location.hostname}</div>
                    <div>Back End:</div>
                    <div className="wideColumn">{this.props.endpoints.backend}</div>
                    <div>Database:</div>
                    <div className="wideColumn">{this.props.endpoints.database}</div>
                    { this.props.modes.map (value => {
                            return <EndpointLabel mode={value.mode} 
                                                  key={value.mode}
                                                  apiHost={value.apiHost}
                                                  modeHandler={this.props.modeHandler}
                                                  currentMode={this.props.mode}/>
                        })
                    }
                </div>
    }
}

export { LibrarySettings } ;