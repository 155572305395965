import React from 'react';

class LibraryController extends React.Component {


    constructor (props) {
        super(props);
        this.bookTitleRef = React.createRef();
        this.bookAuthorRef = React.createRef();
    }

    superRandom(low, high) {
        // imagine that low is 0 and high is 10
        const result = Math.floor(Math.random()*(high - low + 1)) + low
        return result
    }

    randomize = async () => {
        let randomBook = await this.props.randomBook();
        this.bookTitleRef.current.value = randomBook.Book.title; 
        this.bookAuthorRef.current.value = randomBook.Book.author;
    }



    render () {

        return <div className="card two-column-asymmetric controllerCard">

                    <div>{" "}</div>
                    
                    <div className="centered bold orange">
                        {this.props.messages.LibraryController.render.controller}
                    </div>

                    <div>
                        {this.props.messages.LibraryController.render.title}
                    </div>
                    <div className="centered">
                        <input type="text" ref={this.bookTitleRef}/>
                    </div>
                    <div>
                        {this.props.messages.LibraryController.render.author}
                    </div>
                    <div className="centered">
                        <input type="text" ref={this.bookAuthorRef}/>
                    </div>

                    <div>{" "}</div>

                    <div className="centered vpadding">
                        <button className="btnC"
                            onClick={
                                () => {
                                    this.randomize() 
                                }
                            }>
                            {this.props.messages.LibraryController.render.randomize}
                        </button>
                    </div>

                    <div>{" "}</div>

                    <div className="centered vpadding">
                        <button className="btnC"
                            onClick={
                                () => { 
                                    this.props.addBookHandler(
                                        this.bookTitleRef.current.value,
                                        this.bookAuthorRef.current.value,
                                    );
                                }
                            }>
                            {this.props.messages.LibraryController.render.add}
                        </button>
                    </div>

                </div>

    }

}

export { LibraryController } ;